<template>
  <base-view
      @click:add="criar"
      :permiteCadastrar="permiteCadastrar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditOfertaDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <OfertaDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                             :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import OfertaDataTable from "../../components/shared/datatables/OfertaDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/oferta";
import CreateEditOfertaDialog from "../../components/shared/dialogs/CreateEditOfertaDialog";
import {ApiError} from "../../api/core";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions,mapGetters} from "vuex";

export default {
  name: "index",
  components: {CreateEditOfertaDialog, BaseView, OfertaDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      permiteCadastrar: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'ID',
          column: 'id',
          type: 'number',
          prefix: '#'
        },
        {
          name: 'Tipo Item',
          column: 'idTipoItem',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.TIPO_ITEM
          })
        },         
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth',['user','isAuthenticated','nivelUsuarioLogado']),
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {};
      this.editDialogOpened = true;
    },
    editar(objeto) {
      this.item = JSON.parse(JSON.stringify(objeto));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Oferta removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {           
          this.$toast.error(`Não foi possível remover a Oferta.`, {
            timeout: 6000
          })
        }
      }
    },
    async getPermissoes(){
      this.permiteCadastrar = await this.getPermissaoPorItemPerfil("Oferta.cadastro",this.nivelUsuarioLogado);
    },
  },
  mounted() {
    this.getPermissoes();
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>